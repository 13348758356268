<template>
  <Layout>
    <title-bar :title-stack="titleStack"/>
    <hero-bar :has-right-visible="false">
      Ocurrió un error
    </hero-bar>
    <section class="section is-main-section">
      <b-button to="/" tag="router-link" type="is-primary">
        <b-icon icon="arrow-left" custom-size="default" class="mr-1"></b-icon>
        Volver a Inicio
      </b-button>
    </section>
  </Layout>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Layout from '@/components/Layout'
export default {
  name: 'Error',
  components: {
    HeroBar,
    TitleBar,
    Layout
  },
  computed: {
    titleStack () {
      return [
        'Error'
      ]
    }
  },
  created () {
    this.$buefy.toast.open({
      message: 'Sucedió algo inesperado.',
      type: 'is-danger'
    })
  }
}
</script>
